var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    ref: "tabsRoot",
    staticClass: "tabs",
    class: _vm.$mq
  }, [_vm._l(_vm.tabs, function (tab, i) {
    return _c('div', {
      key: i,
      ref: "tabs",
      refInFor: true,
      staticClass: "tab",
      class: ({
        active: _vm.activeTab === i
      }, _vm.$mq),
      on: {
        "click": function ($event) {
          return _vm.changeTab(i);
        }
      }
    }, [_vm._v(" " + _vm._s(tab) + " ")]);
  }), _c('div', {
    staticClass: "tabs__guide"
  }, [_c('div', {
    staticClass: "tabs__indicator",
    style: _vm.indicatorStyles
  })])], 2), _c('swiper', {
    ref: "swiper",
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOption
    },
    on: {
      "slide-change": _vm.onSlideChange
    }
  }, [_c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "640",
      "height": "462",
      "src": require(`@/assets/images/features-page/tabs/Product_Tour_1.png`)
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "64",
      "height": "64",
      "rx": "32",
      "fill": "#FFA83F",
      "fill-opacity": "0.15"
    }
  }), _c('path', {
    attrs: {
      "d": "M44.8 18H19.2C17.4352 18 16 19.4468 16 21.2258V38.9677C16 40.7468 17.4352 42.1935 19.2 42.1935H25.6L22.72 46.0645L25.28 48L29.6 42.1935H34.4L38.72 48L41.28 46.0645L38.4 42.1935H44.8C46.5648 42.1935 48 40.7468 48 38.9677V21.2258C48 19.4468 46.5648 18 44.8 18ZM19.2 38.9677V21.2258H44.8L44.8016 38.9677H19.2Z",
      "fill": "#B7602F"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.4 32.5161H28.8V35.7419H22.4V32.5161Z",
      "fill": "#B7602F"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Аналитика в любых"), _c('br'), _vm._v("разрезах")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Вы можете анализировать данные за любой доступный период времени и по любым срезам: категории, бренды, продавцы, товары, размеры, цвета и комплектации ")])])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "639",
      "height": "463",
      "src": require(`@/assets/images/features-page/tabs/Product_Tour_2.png`)
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "40",
      "height": "40",
      "rx": "20",
      "fill": "#FE4D58",
      "fill-opacity": "0.15"
    }
  }), _c('path', {
    attrs: {
      "d": "M21 14H23V25H21V14ZM25 11H27V25H25V11ZM17 17H19V25H17V17ZM12 27H28V29H12V27ZM13 20H15V25H13V20Z",
      "fill": "#824145"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Динамика показателей")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Динамика ключевых показателей позволяет увидеть картину целиком: как влияет цена товара на продажи, категория растет или продажи замедляются? ")])])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "643",
      "height": "464",
      "src": require(`@/assets/images/features-page/tabs/Product_Tour_3.png`)
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "40",
      "height": "40",
      "rx": "20",
      "fill": "#9D6DFF",
      "fill-opacity": "0.15"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.2 12H12.8C11.8073 12 11 12.7973 11 13.7778V26.2222C11 27.2027 11.8073 28 12.8 28H27.2C28.1927 28 29 27.2027 29 26.2222V13.7778C29 12.7973 28.1927 12 27.2 12ZM12.8 26.2222V13.7778H27.2L27.2018 26.2222H12.8Z",
      "fill": "#4B3A70"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.6 15.5556H25.4V17.3333H14.6V15.5556ZM14.6 19.1111H25.4V20.8889H14.6V19.1111ZM14.6 22.6667H20V24.4444H14.6V22.6667Z",
      "fill": "#4B3A70"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Категории и позиции")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Отслеживайте изменение видимости своих товаров и товаров конкурентов во всех категориях ")])])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "642",
      "height": "464",
      "src": require(`@/assets/images/features-page/tabs/Product_Tour_4.png`)
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "version": "1.1",
      "id": "svg9",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:svg": "http://www.w3.org/2000/svg"
    }
  }, [_c('defs', {
    attrs: {
      "id": "defs13"
    }
  }), _c('rect', {
    staticStyle: {
      "fill": "#ffa83f",
      "fill-opacity": "0.14902"
    },
    attrs: {
      "width": "40",
      "height": "40",
      "rx": "20",
      "fill": "#00CF2B",
      "fill-opacity": "0.15",
      "id": "rect2",
      "x": "0",
      "y": "0"
    }
  }), _c('path', {
    attrs: {
      "d": "M 27.414,24.586 22.337,19.509 C 23.386,17.928 24,16.035 24,14 24,8.486 19.514,4 14,4 8.486,4 4,8.486 4,14 c 0,5.514 4.486,10 10,10 2.035,0 3.928,-0.614 5.509,-1.663 l 5.077,5.077 c 0.78,0.781 2.048,0.781 2.828,0 0.781,-0.781 0.781,-2.047 0,-2.828 z M 7,14 c 0,-3.86 3.14,-7 7,-7 3.86,0 7,3.14 7,7 0,3.86 -3.14,7 -7,7 -3.86,0 -7,-3.14 -7,-7 z",
      "id": "XMLID_223_"
    }
  }), _c('path', {
    staticStyle: {
      "fill": "#ffa83f",
      "fill-opacity": "1",
      "stroke-width": "0.782788"
    },
    attrs: {
      "d": "M 29.338549,26.295327 25.364332,22.32111 c 0.821145,-1.237589 1.301777,-2.719407 1.301777,-4.312382 0,-4.316295 -3.511589,-7.827883 -7.827885,-7.827883 -4.316295,0 -7.827884,3.511588 -7.827884,7.827883 0,4.316296 3.511589,7.827885 7.827884,7.827885 1.592975,0 3.074793,-0.480632 4.312382,-1.301777 l 3.974217,3.974217 c 0.610575,0.611357 1.603151,0.611357 2.213726,0 0.611357,-0.611358 0.611357,-1.602368 0,-2.213726 z M 13.358705,18.008728 c 0,-3.021563 2.457956,-5.479519 5.479519,-5.479519 3.021564,0 5.47952,2.457956 5.47952,5.479519 0,3.021564 -2.457956,5.47952 -5.47952,5.47952 -3.021563,0 -5.479519,-2.457956 -5.479519,-5.47952 z",
      "id": "XMLID_223_-3"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v(" Подбор запросов и мониторинг позиций ")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Анализируйте самые эффективные запросы для оптимизации поискового трафика. "), _c('br'), _vm._v(" Запустите мониторинг позиций для ежедневного анализа видимости ваших товаров по целевым поисковым запросам на Wildberries и OZON. ")])])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "642",
      "height": "464",
      "src": require(`@/assets/images/features-page/tabs/Product_Tour_5.png`)
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "40",
      "height": "40",
      "rx": "20",
      "fill": "#00CF2B",
      "fill-opacity": "0.15"
    }
  }), _c('path', {
    attrs: {
      "d": "M11 12V28.0556C11 28.306 11.1014 28.5463 11.2818 28.7234C11.4623 28.9005 11.707 29 11.9622 29H29V27.1111H12.9244V12H11Z",
      "fill": "#325F3C"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.8284 23.0566C22.9177 23.1444 23.0237 23.214 23.1405 23.2615C23.2572 23.3091 23.3823 23.3335 23.5087 23.3335C23.6351 23.3335 23.7602 23.3091 23.8769 23.2615C23.9936 23.214 24.0997 23.1444 24.189 23.0566L29 18.3344L27.6394 16.9989L23.5087 21.0534L21.3023 18.8878C21.2131 18.8001 21.107 18.7304 20.9903 18.6829C20.8736 18.6354 20.7484 18.6109 20.6221 18.6109C20.4957 18.6109 20.3706 18.6354 20.2538 18.6829C20.1371 18.7304 20.0311 18.8001 19.9418 18.8878L15.1308 23.6101L16.4913 24.9455L20.6221 20.891L22.8284 23.0566Z",
      "fill": "#325F3C"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Умные рейтинги")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Найти категории с максимальным процентом продаваемых товаров? "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("– пару секунд.")]), _c('br'), _vm._v(" Найти самые растущие новинки за последний месяц среди всех товаров маркетплейса? "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("– 2 клика")])])])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "645",
      "height": "466",
      "src": require(`@/assets/images/features-page/tabs/lists.png`),
      "srcset": `${require(`@/assets/images/features-page/tabs/lists@2x.png`)} 2x`
    }
  })]), _c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "40",
      "height": "40",
      "rx": "20",
      "fill": "#5FBCFF",
      "fill-opacity": "0.15"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.2 12H12.8C11.8073 12 11 12.7973 11 13.7778V26.2222C11 27.2027 11.8073 28 12.8 28H27.2C28.1927 28 29 27.2027 29 26.2222V13.7778C29 12.7973 28.1927 12 27.2 12ZM27.2 13.7778L27.2009 17.3333H12.8V13.7778H27.2ZM12.8 26.2222V19.1111H27.2009L27.2018 26.2222H12.8Z",
      "fill": "#3F5F76"
    }
  }), _c('path', {
    attrs: {
      "d": "M21.8 14.6667H23.6V16.4444H21.8V14.6667ZM24.5 14.6667H26.3V16.4444H24.5V14.6667Z",
      "fill": "#3F5F76"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Пользовательские списки")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Создавайте свои списки (подборки) по любым сущностями для быстрого доступа и оперативного мониторинга. Например, списки конкурирующих брендов или список потенциальных товаров/категорий для расширения ассортимента ")])])])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };