import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

// https://github.com/nolimits4web/swiper/blob/Swiper5/API.md
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      tabs: ["Аналитика", "Динамика", "Позиции", "Запросы (SEO)", "Рейтинги", "Списки"],
      activeTab: 0,
      indicatorStyles: {},
      swiperOption: {}
    };
  },
  mounted() {
    this.refreshIndicator();
  },
  methods: {
    changeTab(i) {
      this.activeTab = i;
      this.refreshIndicator();
      this.$refs.swiper.$swiper.slideTo(i);
    },
    refreshIndicator() {
      const rootRect = this.$refs.tabsRoot.getBoundingClientRect();
      const activeRect = this.$refs.tabs[this.activeTab].getBoundingClientRect();
      this.indicatorStyles = {
        left: activeRect.left - rootRect.left + "px",
        right: rootRect.right - activeRect.right + "px"
      };
    },
    onSlideChange() {
      this.activeTab = this.$refs.swiper.$swiper.activeIndex;
      this.refreshIndicator();
    }
  }
};