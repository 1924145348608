import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import FeaturesTabs from "./FeaturesTabs";
export default {
  meta: {
    /*@V:
            title: 'Возможности сервиса',
            meta: [
                { name: 'description', content: 'SalesFinder - Поиск прибыльных ниш, анализ цен и продаж конкурентов, трекинг позиций, данные по брендам и продавцам. Удобная аналитика маркетплейсов для увеличения ваших продаж!' },
            ]
            */
    ////&: 2022-06-24   title: "SalesFinder - Обзор возможностей сервиса, инструменты для анализа Wildberries и OZON",
    title: "Обзор возможностей сервиса, инструменты для анализа Wildberries и OZON",
    meta: [{
      name: "description",
      content: "SalesFinder - Эффективные инструменты для выбора ниши, поиска товаров и оптимизации продаж на маркетплейсах. Детальная аналитика продаж по категориям, товарам, брендам и селлерам."
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  components: {
    PublicHeader,
    FeaturesTabs,
    PublicFooter
  }
};