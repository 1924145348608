var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-page",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "index-page__background",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/features_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "959",
      "viewBox": "0 0 1440 959",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.4",
      "filter": "url(#filter0_f_1202:7990)"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask0_1202:7990",
      "maskUnits": "userSpaceOnUse",
      "x": "971",
      "y": "254",
      "width": "546",
      "height": "476"
    }
  }, [_c('ellipse', {
    attrs: {
      "cx": "1244",
      "cy": "491.774",
      "rx": "273",
      "ry": "237.774",
      "fill": "#C4C4C4"
    }
  })]), _c('g', {
    attrs: {
      "mask": "url(#mask0_1202:7990)"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.2",
      "filter": "url(#filter1_f_1202:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1550.77 178.68C1550.77 178.68 1715.14 476.562 1672.81 582.93C1630.49 689.298 1035.88 825.451 927.338 792.686C818.798 759.921 807.799 498.08 850.127 391.712C892.455 285.344 1550.77 178.68 1550.77 178.68Z",
      "fill": "#A09EFF"
    }
  })])])]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter2_f_1202:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M588.078 227.575C588.078 227.575 622.205 307.177 612.14 336.215C602.076 365.253 309.789 448.779 286.111 440.573C262.434 432.366 261.838 361.584 271.902 332.546C281.966 303.508 588.078 227.575 588.078 227.575Z",
      "fill": "#A09EFF",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter3_f_1202:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1413.08 -93.4254C1413.08 -93.4254 1447.2 -13.8229 1437.14 15.2153C1427.08 44.2534 1134.79 127.779 1111.11 119.573C1087.43 111.366 1086.84 40.5841 1096.9 11.546C1106.97 -17.4922 1413.08 -93.4254 1413.08 -93.4254Z",
      "fill": "#FFECF3",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter4_f_1202:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M53.9581 193.864C53.9581 193.864 157.59 173.268 187.704 193.131C217.817 212.994 214.482 590.349 196.912 616.985C179.342 643.622 97.3548 625.762 67.2413 605.9C37.1277 586.037 53.9581 193.864 53.9581 193.864Z",
      "fill": "#FFF3E3"
    }
  })]), _c('g', {
    attrs: {
      "filter": "url(#filter5_f_1202:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M803.948 202.952C803.948 202.952 851.349 301.577 839.143 336.794C826.937 372.011 655.472 417.089 624.173 406.241C592.873 395.393 589.702 308.701 601.908 273.484C614.114 238.267 803.948 202.952 803.948 202.952Z",
      "fill": "#A09EFF"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1202:7990",
      "x": "918",
      "y": "201",
      "width": "652",
      "height": "581.548",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "26.5",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1202:7990",
      "x": "381.174",
      "y": "-267.32",
      "width": "1744.61",
      "height": "1510.97",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "223",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_f_1202:7990",
      "x": "-62.2729",
      "y": "-100.425",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_f_1202:7990",
      "x": "762.727",
      "y": "-421.425",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_f_1202:7990",
      "x": "-278.73",
      "y": "-143.511",
      "width": "816.988",
      "height": "1101.95",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter5_f_1202:7990",
      "x": "267.289",
      "y": "-125.048",
      "width": "901.864",
      "height": "860.933",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1202:7990"
    }
  })], 1)])])]), _c('public-header'), _c('section', {
    staticClass: "hero"
  }, [_c('h1', {
    staticClass: "h1 hero__title",
    class: _vm.$mq
  }, [_vm._v(" Возможности сервиса SalesFinder ")]), _c('div', {
    staticClass: "hero__cards",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "hero-card",
    class: _vm.$mq
  }, [_c('h2', {
    staticClass: "hero-card__title",
    class: _vm.$mq
  }, [_vm._v(" Для старта"), _c('br'), _vm._v("на маркетплейсах ")]), _c('div', {
    staticClass: "hero-card__content"
  }, [_c('ul', {
    staticClass: "custom-ul"
  }, [_c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Узнайте, что реально продается на маркетплейсах ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Найдите категории с высоким спросом ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Начните продавать с минимальным риском ")])])]), _c('div', {
    staticClass: "hero-card__image-wrapper"
  }, [_c('svg', {
    staticClass: "hero-card__image",
    attrs: {
      "width": "140",
      "height": "205",
      "viewBox": "0 0 140 189",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.7"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M105 42C99.201 42 94.5 37.299 94.5 31.5V10.5C94.5 4.70101 99.201 7.92131e-07 105 0C110.799 -7.92131e-07 115.5 4.70101 115.5 10.5V31.5C115.5 37.299 110.799 42 105 42Z",
      "fill": "#FFEFDB",
      "fill-opacity": "0.6"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M210 63H0V94.5C3.93941 94.5 5.90911 94.5 7.63949 94.7912C14.8274 96.0009 20.8739 100.851 23.614 107.606C24.2737 109.232 24.701 111.154 25.5555 115L34.6913 156.111C38.1952 171.878 39.9471 179.762 45.7053 184.381C51.4636 189 59.5395 189 75.6912 189H134.309C150.461 189 158.536 189 164.295 184.381C170.053 179.762 171.805 171.878 175.309 156.111L184.444 115C185.299 111.155 185.726 109.231 186.386 107.606C189.126 100.851 195.173 96.0009 202.361 94.7912C204.091 94.5 206.061 94.5 210 94.5V63ZM78.6071 113.774C77.6538 108.054 72.2439 104.19 66.5238 105.143C60.8037 106.096 56.9395 111.506 57.8929 117.226L63.1429 148.726C64.0962 154.446 69.5061 158.31 75.2262 157.357C80.9463 156.404 84.8105 150.994 83.8571 145.274L78.6071 113.774ZM152.106 117.226C153.059 111.506 149.195 106.096 143.475 105.143C137.755 104.19 132.345 108.054 131.392 113.774L126.142 145.274C125.188 150.994 129.053 156.404 134.773 157.357C140.493 158.31 145.903 154.446 146.856 148.726L152.106 117.226Z",
      "fill": "#FFEFDB",
      "fill-opacity": "0.6"
    }
  })])])])]), _c('div', {
    staticClass: "hero-card",
    class: _vm.$mq
  }, [_c('h2', {
    staticClass: "hero-card__title",
    class: _vm.$mq
  }, [_vm._v(" Для действующих"), _c('br'), _vm._v(" продавцов ")]), _c('div', {
    staticClass: "hero-card__content"
  }, [_c('ul', {
    staticClass: "custom-ul custom-ul--violet"
  }, [_c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Анализируйте стратегию продаж конкурентов ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Эффективно расширяйте ассортимент ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Отслеживаете новые тренды в ваших категориях ")])])]), _c('div', {
    staticClass: "hero-card__image-wrapper"
  }, [_c('svg', {
    staticClass: "hero-card__image",
    attrs: {
      "width": "123",
      "height": "201",
      "viewBox": "0 0 123 201",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.7"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.2914 16.0465C15.0961 8.35149 15.9984 4.504 18.6897 2.26396C21.3811 0.0239258 25.1014 0.0239258 32.5421 0.0239258H140.458C147.899 0.0239258 151.619 0.0239258 154.31 2.26396C157.002 4.504 157.904 8.35149 159.709 16.0465L171.65 66.9663C172.611 71.0633 173.092 73.1118 172.986 74.7757C172.711 79.0902 170 82.7917 166.14 84.1224C164.651 84.6355 162.625 84.6355 158.571 84.6355C153.282 84.6355 150.637 84.6355 148.415 84.0839C142.192 82.5385 137.048 77.893 134.595 71.6034C133.719 69.3584 133.294 66.6403 132.444 61.2041C132.207 59.6832 132.088 58.9227 131.953 58.7226C131.557 58.1351 130.737 58.1351 130.341 58.7226C130.206 58.9227 130.087 59.6832 129.85 61.2041L129.041 66.3786C128.955 66.9271 128.912 67.2014 128.869 67.4417C127.118 77.2374 119.187 84.3993 109.835 84.6299C109.606 84.6355 109.345 84.6355 108.824 84.6355C108.302 84.6355 108.041 84.6355 107.812 84.6299C98.4596 84.3993 90.5288 77.2374 88.778 67.4417C88.735 67.2014 88.6921 66.9271 88.6064 66.3786L87.7974 61.2041C87.5596 59.6832 87.4407 58.9227 87.3058 58.7226C86.91 58.1351 86.09 58.1351 85.6942 58.7226C85.5593 58.9227 85.4404 59.6832 85.2026 61.2041L84.3936 66.3786C84.3079 66.9271 84.265 67.2014 84.222 67.4417C82.4712 77.2374 74.5404 84.3993 65.1884 84.6299C64.959 84.6355 64.6982 84.6355 64.1765 84.6355C63.6548 84.6355 63.3939 84.6355 63.1646 84.6299C53.8125 84.3993 45.8817 77.2374 44.1309 67.4417C44.088 67.2014 44.0451 66.9271 43.9593 66.3786L43.1503 61.2041C42.9125 59.6832 42.7936 58.9227 42.6588 58.7226C42.2629 58.1351 41.443 58.1351 41.0471 58.7226C40.9123 58.9227 40.7934 59.6832 40.5556 61.2041C39.7057 66.6403 39.2807 69.3584 38.4051 71.6034C35.952 77.893 30.8077 82.5385 24.5846 84.0839C22.3633 84.6355 19.7185 84.6355 14.4289 84.6355C10.3755 84.6355 8.34876 84.6355 6.86021 84.1224C3.00041 82.7917 0.289322 79.0902 0.0144508 74.7757C-0.0915542 73.1118 0.388862 71.0633 1.3497 66.9663L13.2914 16.0465Z",
      "fill": "#E9E5FF",
      "fill-opacity": "0.6"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.2224 100.502C17.049 104.954 17.049 110.18 17.049 116.365V179.824C17.049 189.795 17.049 194.781 19.955 197.879C22.861 200.977 27.538 200.977 36.8922 200.977H66.6569V158.671C66.6569 152.829 71.0989 148.094 76.5784 148.094H96.4216C101.901 148.094 106.343 152.829 106.343 158.671V200.977H136.108C145.462 200.977 150.139 200.977 153.045 197.879C155.951 194.781 155.951 189.795 155.951 179.824V116.365C155.951 110.18 155.951 104.954 155.778 100.502C152.158 100.493 148.456 100.383 145.039 99.5349C139.886 98.2552 135.156 95.761 131.147 92.3222C125.352 97.2943 118.054 100.296 110.18 100.49C109.772 100.5 109.342 100.5 108.931 100.5H108.717C108.306 100.5 107.876 100.5 107.467 100.49C99.5935 100.296 92.2952 97.2943 86.5 92.3222C80.7049 97.2943 73.4065 100.296 65.5325 100.49C65.1245 100.5 64.6949 100.5 64.2842 100.5H64.0696C63.6589 100.5 63.2285 100.5 62.8204 100.49C54.9464 100.296 47.6481 97.2943 41.8529 92.3222C37.8443 95.761 33.1141 98.2552 27.9611 99.5349C24.544 100.383 20.8417 100.493 17.2224 100.502Z",
      "fill": "#E9E5FF",
      "fill-opacity": "0.6"
    }
  })])])])])])]), _c('section', {
    staticClass: "section",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h1 section__title",
    class: _vm.$mq
  }, [_vm._v(" 110+ млн товаров и тысячи категорий "), _c('br'), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("как на ладони")])]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_vm._m(0)]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "position": "relative"
    }
  }, [_c('img', {
    attrs: {
      "width": "590",
      "height": "422",
      "src": require(`@/assets/images/features-page/data.png`),
      "srcset": `${require(`@/assets/images/features-page/data@2x.png`)} 2x`
    }
  }), _c('svg', {
    staticStyle: {
      "position": "absolute",
      "overflow": "visible",
      "left": "calc(50% - 125px / 2)",
      "top": "calc(50% - 332px / 2)",
      "filter": "blur(319px)",
      "margin-left": "150px"
    },
    attrs: {
      "width": "125",
      "height": "332",
      "viewBox": "0 0 125 332",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.8",
      "d": "M12.2216 1.8424C12.2216 1.8424 90.822 -4.145 112.875 13.0626C134.927 30.2703 120.924 306.592 106.893 324.573C92.8626 342.555 31.719 322.255 9.66652 305.047C-12.386 287.84 12.2216 1.8424 12.2216 1.8424Z",
      "fill": "#514E62"
    }
  })])])])])]), _c('section', {
    staticClass: "section section--gray",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h1 section__title",
    class: _vm.$mq
  }, [_vm._v(" Данные, с которыми "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("приятно работать")])]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq,
    staticStyle: {
      "margin-top": "-16px"
    }
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Все отчеты в сервисе SalesFinder созданы для удобства и простоты вашей работы с данными ")]), _c('div', {
    staticClass: "feature__text"
  }, [_c('ul', {
    staticClass: "custom-ul custom-ul--violet"
  }, [_c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Гибкие фильтры ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Настройки вида таблиц ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Сортировка по всем параметрам ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Экспорт данных ")])])])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq
  }, [_c('img', {
    attrs: {
      "width": "555",
      "height": "453",
      "src": require(`@/assets/images/features-page/features.png`),
      "srcset": `${require(`@/assets/images/features-page/features@2x.png`)} 2x`
    }
  })])]), _c('div', {
    staticClass: "features-tabs"
  }, [_c('features-tabs')], 1)])]), _c('section', {
    staticClass: "section",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "analysis"
  }, [_c('h2', {
    staticClass: "h1 section__title",
    class: _vm.$mq
  }, [_vm._v(" Анализ показателей и инструменты оптимизации для "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("вашего магазина")])]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq,
    staticStyle: {
      "margin-top": "-16px"
    }
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Интеграция магазина по API позволяет получить детальную и понятную аналитику для роста вашего бизнеса ")]), _c('div', {
    staticClass: "feature__text"
  }, [_c('ul', {
    staticClass: "custom-ul custom-ul--violet"
  }, [_c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Подключение магазина на OZON и Wildberries ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Обзор ключевых метрик ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Детальная аналитика продаж ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" ABC анализ ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Расчет оптимальной поставки ")])])])]), _c('div', {
    staticClass: "feature__image analysis-images",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "screenshot analysis-images__item",
    class: _vm.$mq,
    attrs: {
      "width": "555",
      "height": "453",
      "src": require(`@/assets/images/features-page/analysis/1.png`)
    }
  }), _c('img', {
    staticClass: "screenshot analysis-images__item",
    class: _vm.$mq,
    attrs: {
      "width": "555",
      "height": "453",
      "src": require(`@/assets/images/features-page/analysis/2.png`)
    }
  }), _c('img', {
    staticClass: "screenshot analysis-images__item",
    class: _vm.$mq,
    attrs: {
      "width": "555",
      "height": "453",
      "src": require(`@/assets/images/features-page/analysis/3.png`)
    }
  })])])])])]), _c('section', {
    staticClass: "section",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Custom-отчеты ")]), _c('div', {
    staticClass: "feature__text"
  }, [_c('p', [_vm._v("Нужен нестандартный отчет?")]), _c('p', [_vm._v(" Мы можем настроить индивидуальные отчеты и сценарии мониторинга под ваши бизнес-требования, например: мониторинг рекомендованной розничной цены ваших товаров, отслеживания выдачи по вашим индивидуальным критериям, мониторинг контента и многое другое. ")]), _c('p', {
    staticClass: "mt32"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Оставить заявку")])], 1)])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "position": "relative"
    }
  }, [_c('img', {
    staticClass: "screenshot",
    attrs: {
      "width": "592",
      "height": "441",
      "src": require(`@/assets/images/features-page/custom.png`),
      "srcset": `${require(`@/assets/images/features-page/custom@2x.png`)} 2x`
    }
  })])]), _c('div', {
    staticClass: "dark-card",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "dark-card__content",
    class: _vm.$mq
  }, [_vm._m(1), _c('h3', {
    staticClass: "dark-card__title"
  }, [_vm._v("API для интеграции")]), _c('div', {
    staticClass: "dark-card__text"
  }, [_vm._v(" Интегрируйте функционал и данные сервиса SalesFinder в свои собственные приложения и корпоративные системы через API интерфейс."), _c('br'), _vm._v(" Если вы хотите узнать больше про возможности интеграции - "), _c('router-link', {
    attrs: {
      "to": {
        name: 'Contact'
      }
    }
  }, [_vm._v("свяжитесь с нами")])], 1)]), _c('div', {
    staticClass: "dark-card__image",
    class: _vm.$mq,
    staticStyle: {
      "position": "relative"
    }
  }, [_c('img', {
    attrs: {
      "width": "685",
      "height": "439",
      "src": require(`@/assets/images/features-page/code.png`),
      "srcset": `${require(`@/assets/images/features-page/code@2x.png`)} 2x`
    }
  })]), _c('svg', {
    staticClass: "dark-card__lights",
    attrs: {
      "width": "1440",
      "height": "974",
      "viewBox": "0 0 1440 974",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter0_f_1219:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M360.731 328.837C360.731 328.837 408.132 427.462 395.926 462.679C383.72 497.896 212.255 542.975 180.955 532.127C149.656 521.279 146.484 434.586 158.69 399.369C170.896 364.152 360.731 328.837 360.731 328.837Z",
      "fill": "#FFE49E"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.7",
      "filter": "url(#filter1_f_1219:7990)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1223.22 378.499C1223.22 378.499 1284.9 506.822 1269.01 552.644C1253.13 598.466 1030.03 657.119 989.311 643.004C948.586 628.889 944.459 516.092 960.341 470.27C976.222 424.448 1223.22 378.499 1223.22 378.499Z",
      "fill": "#969696"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1219:7990",
      "x": "-175.929",
      "y": "0.836914",
      "width": "901.864",
      "height": "860.934",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1219:7990"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1219:7990",
      "x": "623.729",
      "y": "50.499",
      "width": "975.899",
      "height": "922.644",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1219:7990"
    }
  })], 1)])])])])]), _c('public-footer')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "feature__text"
  }, [_c('p', [_vm._v(" Ежедневно наши серверы мониторинга сканируют выдачу маркетплейсов Wildberries и OZON по всем категориям, сохраняя доступную информацию по абсолютно всем товарам: цены, остатки, отзывы, рейтинг и др. ")]), _c('p', [_vm._v(" После сбора информации специальные алгоритмы обработки данных формируют структурированные отчеты для удобного анализа продаж и трендов любых сущностей: категории, товары, бренды и продавцы. ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dark-card__prefix"
  }, [_c('div', {
    staticClass: "badge"
  }, [_vm._v("Скоро")])]);
}];
export { render, staticRenderFns };